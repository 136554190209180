.card{
  background: white;
  padding: 2rem;
  position: relative;
  z-index: 1;
  @media (min-width: $screen-md-min) {
    padding: 5rem;
  }
}

.card-container{
  .card-image{
    height: 480px;
    margin-top: -250px;
    background-size: cover;
    background-repeat: no-repeat;
  }
}
