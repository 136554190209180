.template-example{
  margin-bottom: 30px;
  .template-title-example{
    
    font-weight: normal;
    color: #34495E;

    &:after{
      display: none;
    }
  }
  h2{
    font-size: 32px;
  }
  h3{
    font-size: 20px;
  }

  .table>tbody>tr>td, .table>tbody>tr>th, .table>tfoot>tr>td, .table>tfoot>tr>th, .table>thead>tr>td, .table>thead>tr>th {
    padding: 0 15px;
  }
}


.list-icons{
  list-style: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  li{
    float: left;
    margin-right: 5px;
  }
  
}


span[role="button"] {
  cursor: pointer;
  text-decoration: underline;
}

.logos {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 30px;
}
.logos:last-of-type {
  margin-bottom: 0;
}
